import React from "react";
import styles from "./redeemed-miles-details.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { normalDateFormat } from "../../../../../utils/helper";
import moment from "moment";

const RedeemedMilesDetails: React.FC = (data: any) => {
  const { milesAwardDetail } = useSelector(
    (state: RootState) => state?.osciReducer?.mileageStatement
  );

  const { t } = useTranslation();
  const { awardInfos } = milesAwardDetail;

  if (!milesAwardDetail) return null;
  return (
    <>
      {milesAwardDetail && awardInfos && (
        <div className={styles["redeem-mileage-wrapper"]}>
          <div className={styles["redeem-miles-details"]}>
            <div className={styles["redeem-miles-details-header"]}>
              <div>{t("label_miles_redeemed")}</div>
              <div>{t("label_reference_number")}</div>
              <div>{t("label_award_code")}</div>
              {awardInfos[0]?.flightRedemptions.length > 0 && (
                <div>{t("label_requested_for")}</div>
              )}
            </div>
            <div className={styles["redeem-miles-details-data"]}>
              <div>{milesAwardDetail?.awardInfos[0].redeemedPoints}</div>
              <div>{milesAwardDetail.requestNumber}</div>
              <div>{milesAwardDetail?.awardInfos[0].awardCode}</div>
              {awardInfos[0]?.flightRedemptions.length > 0 && (
                <div>
                  {milesAwardDetail?.awardInfos[0]?.awardUsers[0]?.firstName}{" "}
                  {milesAwardDetail?.awardInfos[0]?.awardUsers[0]?.lastName}
                </div>
              )}
            </div>
          </div>

          {awardInfos[0]?.flightRedemptions.length > 0 && (
            <div className={styles["redeem-flight-details-wrapper"]}>
              <div className={styles["redeem-flight-details-header"]}>
                <div>{t("label_depart_date")}</div>
                <div>{t("label_flight_header")}</div>
                <div>{t("label_from_to")}</div>
                <div>{t("label_class")}</div>
                <div>{t("label_ticket_number")}</div>
              </div>
              {awardInfos[0]?.flightRedemptions[0].legInfo.legs.map(
                (z: any, index: number) => (
                  <div className={styles["redeem-flight-details-data"]}>
                    <>
                      <div>{normalDateFormat(moment(z?.flightDate))}</div>
                      <div>
                        {z?.airlineCode}
                        {z?.flightNumber}
                      </div>
                      <div>
                        {z?.origin}-{z?.destination}
                      </div>
                      <div>{z?.className}</div>
                      <div>
                        {awardInfos[0]?.flightRedemptions[0]?.ticketNumber}
                      </div>
                    </>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RedeemedMilesDetails;
