import React from "react";
import styles from "./empty-mileage-data.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import { useTranslation } from "react-i18next";

const EmptyMileageData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["empty-mileage-data-wrapper"]}>
      <TGIcon icon="empty-mileage-data-icon" fillColor="" size="" />
      <div className={styles["empty-mileage-data"]}>
        <h3>{t("label_no_activity")}</h3>
        <div>{t("label_no_activity_content")}</div>
      </div>
    </div>
  );
};

export default EmptyMileageData;
