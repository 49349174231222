// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OWKoVJ_1wdLN9xvZmkkc {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  width: 100%;
}
.OWKoVJ_1wdLN9xvZmkkc .jTWNfRJ3Aq14cXZdijUf {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.OWKoVJ_1wdLN9xvZmkkc .jTWNfRJ3Aq14cXZdijUf > h3 {
  align-self: stretch;
  color: var(--gray-text-strong, #2C3059);
  text-align: center;
  font-family: "Inter-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.OWKoVJ_1wdLN9xvZmkkc .jTWNfRJ3Aq14cXZdijUf > div {
  align-self: stretch;
  color: var(--gray-text, #6A6D87);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/pages/mileage-statement/empty-mileage-data/empty-mileage-data.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;AACR;AACQ;EACI,mBAAA;EACA,uCAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AAEQ;EACI,mBAAA;EACA,gCAAA;EACA,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAZ","sourcesContent":[".empty-mileage-data-wrapper {\r\n    display: flex;\r\n    padding: 24px 0px;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 24px;\r\n    align-self: stretch;\r\n    width: 100%;\r\n\r\n    .empty-mileage-data {\r\n        display: flex;\r\n        flex-direction: column;\r\n        align-items: center;\r\n        align-self: stretch;\r\n\r\n        >h3 {\r\n            align-self: stretch;\r\n            color: var(--gray-text-strong, #2C3059);\r\n            text-align: center;\r\n            font-family: \"Inter-Bold\";\r\n            font-size: 24px;\r\n            font-style: normal;\r\n            font-weight: 700;\r\n            line-height: 150%;\r\n        }\r\n\r\n        >div {\r\n            align-self: stretch;\r\n            color: var(--gray-text, #6A6D87);\r\n            text-align: center;\r\n            font-family: \"Inter\";\r\n            font-size: 16px;\r\n            font-style: normal;\r\n            font-weight: 400;\r\n            line-height: 160%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-mileage-data-wrapper": `OWKoVJ_1wdLN9xvZmkkc`,
	"empty-mileage-data": `jTWNfRJ3Aq14cXZdijUf`
};
export default ___CSS_LOADER_EXPORT___;
