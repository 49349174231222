import React, { useEffect, useState } from "react";
import styles from "./mileage-statement.module.scss";
import { Button } from "react-bootstrap";
import TGButton from "../../../../shared/tg-button";
import TGFloatingSelect from "../../../../shared/tg-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getManageMilesFailure,
  getManageMilesSuccess,
  getMileageStatementDatePending,
  getMileageStatementPending,
  getMilesAwardDetailDataPending,
  getMilesAwardListDataPending,
  getMilesStatementReportPending,
  resetMileageInfoData,
} from "../../slice/mileageStatementSlice";
import { RootState } from "../../slice/RootReducer";
import moment from "moment";
import { isEmptyChecker, normalDateFormat } from "../../../../utils//helper";
import TGIcon from "../../../../shared/tg-icon";
import { config } from "../../../../config/global";
import RedeemedMilesDetails from "./redeemed-miles-details";
import EmptyMileageData from "./empty-mileage-data";
import MobileView from "./mobile-view";
import useScreenSize from "../../../../utils//hook/useScreenSize";
import { useLocation, useNavigate } from "react-router-dom";
import ManageMilesSlider from "./mobile-view/manage-miles-slider";
import { TGRadio } from "../../../../shared/tg-radio";
import MonthYearCalendar from "./month-year-calendar";
import { fetchProfileRequest } from "../../slice/fetchProfileSlice";

export const ManageMilesCard = (x: any, index: number) => {
  return (
    <div
      className={styles["mileage-manage-card-section"]}
      key={`milescard${index}`}
    >
      <div className={styles["mileage-manage-card-image"]}>
        <img src={x.image} alt={`mileage${index}`} />
      </div>
      <div className={styles["mileage-manage-card-detail"]}>
        <h4>{x.label}</h4>
        <p>{x.description}</p>
      </div>
    </div>
  );
};

const MileageStatement: React.FC = () => {
  // const sliderRef = useRef<Slider>(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("All Transactions");
  const filterTypeRadio = [
    { id: 0, label: t("label_byperiod") },
    { id: 1, label: t("label_bydate") },
  ];
  const [radioFilter, setRadioFilter] = useState<any>(filterTypeRadio[0]);
  const [selectedFilterType, setSelectedFilterType] = useState({
    value: "By Period",
    listName: t("label_byperiod"),
  });
  const [activeMonth, setActiveMonth] = useState({
    label: `1 ${t("label_month")}`,
    value: "1",
  });
  const [selectButton, setSelectButton] = useState({
    print: false,
    download: false,
  });
  const [updateRedeemedMilesData, setUpdateRedeemedMilesData] = useState<any>(
    {}
  );

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [fromDate, setFromDate] = useState({ label: "", format: "" });
  const [toDate, setToDate] = useState({ label: "", format: "" });
  const [closeButton, setCloseButton] = useState(false);

  const {
    data,
    manageMiles,
    milesReportData,
    milesAwardList,
    milesAwardDetail,
  } = useSelector((state: RootState) => state?.osciReducer?.mileageStatement);

  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile?.profileData
  );

  const [privilegeDetails, setPrivilegeDetails] = useState({
    imageStyle: "member",
    tierName: "Basic",
  });

  const value = [
    {
      value: "All Transactions",
      label: t("label_all_transactions"),
      mobileLabel: t("label_all"),
    },
    {
      value: "Miles Earned",
      label: t("label_miles_earned"),
      mobileLabel: t("label_earned"),
    },
    {
      value: "Miles Redeemed",
      label: t("label_miles_redeemed"),
      mobileLabel: t("label_redeemed"),
    },
  ];

  const userFullName = sessionStorage.getItem("FullName");

  const monthsFilter = [
    { label: `1 ${t("label_month")}`, value: "1" },
    { label: `3 ${t("label_months")}`, value: "3" },
    { label: `6 ${t("label_months")}`, value: "6" },
    { label: `1 ${t("label_year")}`, value: "12" },
  ];
  const filterType = [
    { listName: t("label_byperiod"), value: "By Period" },
    { listName: t("label_bydate"), value: "By Date" },
  ];

  const handleProfileNavigation = () => {
    window.open(`/${i18n.language}/myprofile`, "_blank");
  };

  const getPrivilegeCard = () => {
    switch (sessionStorage.getItem("privilegeCard")) {
      case "MEMBER":
        setPrivilegeDetails({
          imageStyle: "member",
          tierName: "Basic",
        });
        break;
      case "SILVER":
        setPrivilegeDetails({
          imageStyle: "silver",
          tierName: "Silver",
        });
        break;
      case "GOLD":
        setPrivilegeDetails({
          imageStyle: "gold",
          tierName: "Gold",
        });
        break;
      case "PLATINUM":
        setPrivilegeDetails({
          imageStyle: "platinum",
          tierName: "Platinum",
        });
        break;
      default:
        setPrivilegeDetails({
          imageStyle: "member",
          tierName: "Basic",
        });
        break;
    }
  };

  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;

  const handleDate = (newDate: string) => {
    const date = new Date(newDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const formatDate = `${year}-${month}`;
    return formatDate;
  };

  const handleTabActive = (data: string) => {
    setActiveTab(data);
    setUpdateRedeemedMilesData({});
    dispatch(resetMileageInfoData());
  };

  const handleMonth = (month: any) => {
    setActiveMonth({ label: month.label, value: month.value });
    setUpdateRedeemedMilesData({});
    dispatch(resetMileageInfoData());
  };

  const handleSelectData = (e: any, selectedValue: any) => {
    setSelectedFilterType(selectedValue);
    setUpdateRedeemedMilesData({});
    dispatch(resetMileageInfoData());
  };

  const clickArrow = (index: number, x: any, requestNumber?: any) => {
    let arr = updateRedeemedMilesData?.map((prevState: any, i: number) => ({
      ...prevState,
      isOpen: !prevState.isOpen ? i === index : false,
    }));
    setUpdateRedeemedMilesData(arr);

    let payload = { requestNo: requestNumber };
    dispatch(getMilesAwardDetailDataPending(payload));
  };

  const handleDownloadStatement = () => {
    setSelectButton({ ...selectButton, download: true });
    if (isEmptyChecker(milesReportData)) {
      let from = moment(fromDate?.label, "MMM YYYY").format("YYYY-MM");
      let to = moment(toDate?.label, "MMM YYYY").format("YYYY-MM");
      const date1 = moment(from, "YYYY-MM");
      const date2 = moment(to, "YYYY-MM");
      const monthDiff = Math.abs(date2.month() - date1.month());
      let payloadData = {};
      if (activeTab !== "All Transactions") {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          filterType: activeTab === "Miles Earned" ? "Earned" : "Redeemed",
          monthData:
            selectedFilterType.value === "By Period"
              ? activeMonth.value
              : monthDiff,
          startMonth: from,
          endMonth: to,
        };
      } else {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          startMonth: from,
          endMonth: to,
          monthData:
            selectedFilterType.value === "By Period"
              ? activeMonth.value
              : monthDiff,
        };
      }
      dispatch(getMilesStatementReportPending(payloadData));
    }
  };

  const handlePrint = () => {
    setSelectButton({ ...selectButton, print: true });
    if (isEmptyChecker(milesReportData)) {
      let from = handleDate(fromDate?.label);
      let to = handleDate(toDate?.label);
      const date1 = moment(from, "YYYY-MM");
      const date2 = moment(to, "YYYY-MM");
      const monthDiff = date2.diff(date1, "months");
      let payloadData = {};
      if (activeTab !== "All Transactions") {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          filterType: activeTab === "Miles Earned" ? "Earned" : "Redeemed",
          monthData:
            selectedFilterType.value === "By Period"
              ? activeMonth.value
              : monthDiff,
          startMonth: from,
          endMonth: to,
        };
      } else {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          startMonth: from,
          endMonth: to,
          monthData:
            selectedFilterType.value === "By Period"
              ? activeMonth.value
              : monthDiff,
        };
      }
      dispatch(getMilesStatementReportPending(payloadData));
    }
  };

  const handleRadioSelection = (e: any) => {
    setRadioFilter(e);
    setSelectedFilterType(filterType[e.id]);
    setUpdateRedeemedMilesData({});
    dispatch(resetMileageInfoData());
  };

  const getSelectedValue = (val: string) => {
    const res = filterTypeRadio?.filter((x: any) => x?.label === val);
    return res?.[0]?.label;
  };

  const handleDateValue = (from: string, to: string) => {
    console.log("date", from, to);
    let formattedFrom;
    let formattedTo;
    if (from) {
      formattedFrom = `${from.split(",")[0]} ${" "} ${from.split(",")[1]}`;
    } else {
      formattedFrom = "";
    }
    if (to) {
      formattedTo = `${to.split(",")[0]} ${" "} ${to.split(",")[1]}`;
    } else {
      formattedTo = "";
    }

    setFromDate({ label: from, format: formattedFrom });
    setToDate({ label: to, format: formattedTo });
  };

  const handleCalendar = (val?: boolean) => {
    setCalendarOpen(!calendarOpen);
    if (calendarOpen) {
      setCloseButton(true);
    }
  };

  useEffect(() => {
    dispatch(fetchProfileRequest());
    getPrivilegeCard();
  }, []);

  useEffect(() => {
    if (!isDesktopView) {
      setSelectedFilterType({
        value: "By Period",
        listName: t("label_byperiod"),
      });
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [isDesktopView, t]);

  useEffect(() => {
    let payloadData;
    if (selectedFilterType.value === "By Period") {
      if (activeTab !== "All Transactions") {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          monthData: activeMonth.value,
          filterBy: activeTab === "Miles Earned" ? "Earned" : "Redeemed",
        };
      } else {
        payloadData = {
          filterData:
            selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
          monthData: activeMonth.value,
        };
      }

      if (activeTab !== "Miles Redeemed") {
        if (isEmptyChecker(data))
          dispatch(getMileageStatementPending(payloadData));
      } else {
        if (isEmptyChecker(milesAwardList))
          dispatch(getMilesAwardListDataPending(payloadData));
      }
    } else {
      let from = moment(fromDate?.label, "MMM YYYY").format("YYYY-MM");
      let to = moment(toDate?.label, "MMM YYYY").format("YYYY-MM");
      const date1 = moment(from, "YYYY-MM");
      const date2 = moment(to, "YYYY-MM");
      const monthDiff = Math.abs(date2.month() - date1.month());
      if (!isEmptyChecker(fromDate?.label) && !isEmptyChecker(toDate?.label)) {
        if (activeTab !== "All Transactions") {
          payloadData = {
            filterBy:
              selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
            startMonth: from,
            endMonth: to,
            month: monthDiff,
            filterType: activeTab === "Miles Earned" ? "Earned" : "Redeemed",
          };
        } else {
          payloadData = {
            filterBy:
              selectedFilterType.value === "By Period" ? "ByPeriod" : "ByDate",
            startMonth: from,
            endMonth: to,
            month: monthDiff,
          };
        }
        console.log("data payload", payloadData, from, to, fromDate, toDate);
        if (activeTab !== "Miles Redeemed") {
          if (isEmptyChecker(data))
            dispatch(getMileageStatementDatePending(payloadData));
        } else {
          if (isEmptyChecker(milesAwardList))
            dispatch(getMilesAwardListDataPending(payloadData));
        }
      }
    }

    if (selectedFilterType.value === "By Period") {
      setFromDate({ label: "", format: "" });
      setToDate({ label: "", format: "" });
    }

    if (isEmptyChecker(manageMiles))
      dispatch({
        type: config.FETCH_DATA_CONSTANT,
        url: config.MANAGE_MILES,
        isTeamsite: true,
        successAction: getManageMilesSuccess,
        errorAction: getManageMilesFailure,
      });
  }, [
    activeTab,
    selectedFilterType,
    activeMonth,
    dispatch,
    data,
    manageMiles,
    milesAwardList,
    closeButton,
  ]);

  useEffect(() => {
    if (Object.keys(milesAwardList).length > 0) {
      let updatedData = milesAwardList?.memberAwards.map(
        (x: any, index: number) => ({
          ...x,
          isOpen: false,
          activityId: index,
        })
      );
      //If we need to add redeem miles in all transactions, need to additional check.
      setUpdateRedeemedMilesData(updatedData);
    }
  }, [milesAwardList, selectedFilterType]);

  useEffect(() => {
    if (Object.keys(milesReportData).length > 0) {
      const byteCharacters = atob(milesReportData.content);
      const byteNumbers = new Array(byteCharacters.length)
        .fill(0)
        .map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      if (selectButton.download) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        if (selectedFilterType.value === "By Period") {
          switch (activeMonth.value) {
            case "1":
              link.download = "MileageStatementReport_1Month.pdf";
              break;
            case "3":
              link.download = "MileageStatementReport_3Months.pdf";
              break;
            case "6":
              link.download = "MileageStatementReport_6Months.pdf";
              break;
            case "12":
              link.download = "MileageStatementReport_1Year.pdf";
              break;
            default:
              break;
          }
        } else {
          let fromMonth = new Date(fromDate?.label).toLocaleString("default", {
            month: "long",
          });
          let toMonth = new Date(toDate?.label).toLocaleString("default", {
            month: "long",
          });
          link.download = `MileageStatementReport_${fromMonth}_${toMonth}.pdf`;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSelectButton({ ...selectButton, download: false });
      }
      if (selectButton.print) {
        const blobURL = URL.createObjectURL(blob);
        window.open(
          blobURL,
          "PRINT",
          `height=${window.innerHeight},width=${window.innerWidth}`
        );
        setSelectButton({ ...selectButton, print: false });
      }
    }
  }, [milesReportData, selectButton]);

  return (
    <div className={styles["mileage-statement-main-container"]}>
      <div>
        {isDesktopView && (
          <div
            className={`${styles["mileage-statement-heading"]} global-content-padding`}
          >
            <div className={styles["mileage-statement-text-container"]}>
              <h4>{t("profile_mileage_button")}</h4>
              <p>{t("label_mileage_statement_content")}</p>
            </div>
            <div
              className={`${styles[privilegeDetails.imageStyle]} ${
                styles["mileage-statement-card-section"]
              }`}
            >
              <div className={styles["mileage-statement-user-container"]}>
                <div className={styles["mileage-statement-card-wrapper"]}>
                  <div className={styles["mileage-statement-user-details"]}>
                    <h4>{userFullName}</h4>
                    <div className={styles["mileage-button-container"]}>
                      <Button
                        className={styles["mileage-my-profile-button"]}
                        aria-label="My Profile Button"
                        onClick={handleProfileNavigation}
                      >
                        {t("label_myprofile")}
                      </Button>
                    </div>
                  </div>
                  <div className={styles["mileage-statement-user-info"]}>
                    <div className={styles["mileage-statement-user-validity"]}>
                      {profileDetails?.privilegeCard !== "MEMBER" ? (
                        <>
                          <p>{t("label_valid_thru")}</p>
                          <h5>
                            {normalDateFormat(
                              moment(profileDetails?.cardExpiryDate)
                            )}
                          </h5>
                        </>
                      ) : (
                        <>
                          <p>{t("label_consent_success_member")}</p>
                          <h5>
                            {normalDateFormat(
                              moment(profileDetails?.enrollDate)
                            )}
                          </h5>
                        </>
                      )}
                    </div>
                    <div
                      className={styles["mileage-statement-current-mileage"]}
                    >
                      <p>{t("profile_current_mileage")}</p>
                      <h5>{profileDetails?.remainingMiles}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${styles["mileage-statement-details"]} ${
            isDesktopView && "global-content-padding"
          }`}
        >
          <div className={styles["mileage-statement-wrapper"]}>
            <div className={styles["mileage-statement-selection-container"]}>
              <div
                className={
                  !isDesktopView
                    ? styles["mileage-statement-mobile-header"]
                    : styles["mileage-statement-desktop-header"]
                }
              >
                <div className={styles["mileage-statement-tab-section"]}>
                  {value.map((data, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleTabActive(data.value)}
                        className={`${
                          activeTab === data.value &&
                          styles["mileage-statement-tab-active"]
                        }`}
                      >
                        {isDesktopView ? data.label : data.mobileLabel}
                      </div>
                    );
                  })}
                </div>
                <div className={styles["mileage-statement-filter-section"]}>
                  <div>
                    {isDesktopView ? (
                      <TGFloatingSelect
                        label={""}
                        isCustomChild={false}
                        listData={filterType}
                        onChange={() => {}}
                        isLoading={false}
                        disableSearch={true}
                        isReadOnly={true}
                        defaultValue={filterType[0]?.listName}
                        onSelect={handleSelectData}
                        offcanvasClass={styles["floating-select-bottomsheet"]}
                        backdropClass={
                          styles["floating-select-bottomsheet-backdrop"]
                        }
                        headerLabel={t("label_transactions")}
                      />
                    ) : (
                      <TGFloatingSelect
                        label={""}
                        isCustomChild={true}
                        listData={filterType}
                        onChange={() => {}}
                        isLoading={false}
                        disableSearch={true}
                        isReadOnly={true}
                        value={getSelectedValue(radioFilter.label)}
                        offcanvasClass={styles["floating-select-bottomsheet"]}
                        backdropClass={
                          styles["floating-select-bottomsheet-backdrop"]
                        }
                        headerLabel={t("label_transactions")}
                      >
                        <TGRadio
                          name="filterType"
                          options={filterTypeRadio}
                          onChange={(e) => {
                            handleRadioSelection(e);
                          }}
                          isBorder={true}
                          isFullWidth={true}
                          align="column"
                          selected={
                            filterTypeRadio?.filter(
                              (x: any) =>
                                radioFilter.label.toLowerCase() ===
                                x?.label?.toLowerCase()
                            )[0]?.id
                          }
                        />
                      </TGFloatingSelect>
                    )}
                  </div>
                  <div
                    className={`${
                      selectedFilterType.value === "By Date" &&
                      styles["date-section-container"]
                    }`}
                  >
                    {selectedFilterType.value === "By Period" ? (
                      monthsFilter.map((month, index) => {
                        return (
                          <TGButton
                            variant={
                              activeMonth.label === month.label
                                ? "primary"
                                : "secondary"
                            }
                            customClass={
                              activeMonth.label === month.label
                                ? "active-button"
                                : "normal-button"
                            }
                            label={month.label}
                            key={index}
                            onClick={() => handleMonth(month)}
                          />
                        );
                      })
                    ) : (
                      <div className={styles["datepicker-select"]}>
                        <div
                          className={styles["datepicker-dates-wrapper"]}
                          onClick={() => handleCalendar()}
                        >
                          <div className={styles["datepicker-first-date"]}>
                            {!isEmptyChecker(fromDate) ? (
                              <div className={styles["datepicker-text"]}>
                                {fromDate?.format}
                              </div>
                            ) : (
                              <div className={styles["placeholder-date-text"]}>
                                {t("label_book_widget_from")}
                              </div>
                            )}
                          </div>
                          <div className={styles["datepicker-last-date"]}>
                            {!isEmptyChecker(toDate) ? (
                              <div className={styles["datepicker-text"]}>
                                {toDate.format}
                              </div>
                            ) : (
                              <div className={styles["placeholder-date-text"]}>
                                {t("label_book_widget_to")}
                              </div>
                            )}
                          </div>
                          <TGIcon icon={"calendar-icon"} fillColor="none" />
                        </div>
                        {calendarOpen && (
                          <MonthYearCalendar
                            sendChildToParent={handleDateValue}
                            setCalendarOpen={setCalendarOpen}
                            buttonHandle={handleCalendar}
                            id="calendar"
                            selectedFilterType={selectedFilterType}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isDesktopView ? (
                <div className={styles["mileage-statement-table-section"]}>
                  {activeTab !== "Miles Redeemed" ? (
                    <>
                      <div className={styles["mileage-statement-header"]}>
                        <div>{t("label_flight_status_date")}</div>
                        <div>{t("label_code")}</div>
                        <div>{t("label_description")}</div>
                        <div>{t("label_class")}</div>
                        <div>{t("label_miles_earned")}</div>
                        {activeTab !== "Miles Earned" && (
                          <div>{t("label_miles_redeemed")}</div>
                        )}
                        <div>{t("label_qualifying_miles")}</div>
                      </div>
                      <div
                        className={styles["mileage-statment-data-container"]}
                      >
                        {data &&
                        Object.keys(data).length > 0 &&
                        Object.keys(data?.activityDetails).length ? (
                          data?.activityDetails?.map(
                            (x: any, index: number) => (
                              <div
                                key={index}
                                className={styles["mileage-statement-data"]}
                              >
                                <div>
                                  {normalDateFormat(moment(x.activityDate))}
                                </div>
                                <div>
                                  {
                                    x?.milesDetails?.filter(
                                      (y: any) =>
                                        y.labelName === t("label_code")
                                    )[0].labelValue
                                  }
                                </div>
                                <div>{x?.description[0]?.description}</div>
                                <div>
                                  {" "}
                                  {
                                    x?.milesDetails?.filter(
                                      (y: any) =>
                                        y.labelName === t("label_class")
                                    )[0]?.labelValue
                                  }
                                </div>
                                <div>
                                  {
                                    x?.milesDetails?.filter(
                                      (y: any) =>
                                        y.labelName === t("label_miles_earned")
                                    )[0]?.labelValue
                                  }
                                </div>
                                {activeTab !== t("label_miles_earned") && (
                                  <div>
                                    {
                                      x?.milesDetails?.filter(
                                        (y: any) =>
                                          y.labelName === "RedeemedMiles"
                                      )[0]?.labelValue
                                    }
                                  </div>
                                )}
                                <div>
                                  {
                                    x?.milesDetails?.filter(
                                      (y: any) =>
                                        y.labelName === "Qualifying Miles"
                                    )[0]?.labelValue
                                  }
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className={styles["empty-element-wrapper"]}>
                            <EmptyMileageData />
                          </div>
                        )}
                      </div>
                      {Object.keys(data).length > 0 &&
                        Object.keys(data?.activityDetails).length > 0 &&
                        activeTab !== t("label_miles_redeemed") && (
                          <div
                            className={
                              styles["mileage-statement-print-section"]
                            }
                          >
                            <div>{t("label_qualifying_miles_description")}</div>
                            <div>
                              <div>
                                <TGButton
                                  variant={"secondary"}
                                  label={t("label_print")}
                                  onClick={handlePrint}
                                />
                              </div>
                              <div>
                                <TGButton
                                  variant={"primary"}
                                  label={t("label_download_eReceipt_button")}
                                  onClick={handleDownloadStatement}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  ) : (
                    <>
                      <div
                        className={styles["mileage-statement-header-redeemed"]}
                      >
                        <div>{t("label_requested_date")}</div>
                        <div>{t("label_award_code")}</div>
                        <div>{t("label_request_number")}</div>
                      </div>
                      <div
                        className={styles["mileage-statment-data-container"]}
                      >
                        {updateRedeemedMilesData &&
                        updateRedeemedMilesData.length > 0 ? (
                          updateRedeemedMilesData?.map(
                            (x: any, index: number) => (
                              <>
                                <div
                                  key={index}
                                  className={
                                    styles["mileage-statement-data-redeemed"]
                                  }
                                >
                                  <div>
                                    {normalDateFormat(moment(x.requestDate))}
                                  </div>
                                  <div>{x?.awardCode}</div>
                                  <div>{x?.requestNumber}</div>
                                  <div
                                    className={styles["miles-redeemed-arrow"]}
                                    onClick={() =>
                                      clickArrow(
                                        x.activityId,
                                        x,
                                        x?.requestNumber
                                      )
                                    }
                                  >
                                    {x?.isOpen ? (
                                      <TGIcon
                                        icon={"up-arrow-icon"}
                                        fillColor="#2D1656"
                                        size="34"
                                        strokeColor="#2D1656"
                                      ></TGIcon>
                                    ) : (
                                      <TGIcon
                                        icon={"down-arrow-icon"}
                                        fillColor="#2D1656"
                                        size="34"
                                        strokeColor="#2D1656"
                                      ></TGIcon>
                                    )}
                                  </div>
                                </div>
                                {x.isOpen && (
                                  <RedeemedMilesDetails {...milesAwardDetail} />
                                )}
                              </>
                            )
                          )
                        ) : (
                          <div className={styles["empty-element-wrapper"]}>
                            <EmptyMileageData />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <MobileView
                    userDetails={data}
                    tabActive={activeTab}
                    redeemedUserList={milesAwardList}
                    onclick={handleDownloadStatement}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles["mileage-manage-section"]} global-content-padding`}
      >
        <div>
          <div className={styles["mileage-manage-heading-section"]}>
            <h4>{t("label_manage_your_miles")}</h4>
            <p>{t("label_manage_your_miles_content")}</p>
          </div>
          {isDesktopView && manageMiles.length > 0 && (
            <div className={styles["mileage-manage-detail-section"]}>
              {manageMiles?.map((x: any, index: number) => (
                <>{ManageMilesCard(x, index)}</>
              ))}
            </div>
          )}

          {!isDesktopView && (
            <>
              <ManageMilesSlider />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MileageStatement;
