import React, { useEffect, useState } from "react";
import styles from "./mobile-view-styles.module.scss";
import { useTranslation } from "react-i18next";
import { normalDateFormat } from "../../../../../utils/helper";
import moment from "moment";
import TGIcon from "../../../../../shared/tg-icon";
import { getMilesAwardDetailDataPending } from "../../../slice/mileageStatementSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import EmptyMileageData from "../empty-mileage-data";
import TGButton from "../../../../../shared/tg-button";
import { Button } from "react-bootstrap";

interface UserDetailsType {
  userDetails?: any | null;
  tabActive: string;
  redeemedUserList?: any;
  onclick?: any;
}

const MobileView: React.FC<UserDetailsType> = ({
  userDetails,
  tabActive,
  redeemedUserList,
  onclick,
}) => {
  const { t } = useTranslation();
  const [updateRedeemedMilesData, setUpdateRedeemedMilesData] = useState<any>(
    {}
  );
  const dispatch = useDispatch();
  const { milesAwardDetail } = useSelector(
    (state: RootState) => state?.osciReducer?.mileageStatement
  );

  const { awardInfos } = milesAwardDetail;

  const clickArrow = (index: number, x: any, requestNumber?: any) => {
    let arr = x?.map((prevState: any, id: number) => ({
      ...prevState,
      isOpen: !prevState.isOpen ? id === index : false,
    }));
    setUpdateRedeemedMilesData(arr);

    if (tabActive === "Miles Redeemed") {
      let payload = { requestNo: requestNumber };
      dispatch(getMilesAwardDetailDataPending(payload));
    }
  };

  const handleArray = (details: any) => {
    let updatedData = details?.map((x: any, index: number) => ({
      ...x,
      isOpen: false,
    }));
    //If we need to add redeem miles in all transactions, need to additional check.
    // setUpdatedMileagestatementData(updatedData);
    setUpdateRedeemedMilesData(updatedData);
  };

  useEffect(() => {
    if (tabActive !== "Miles Redeemed") {
      if (Object.keys(userDetails).length > 0) {
        handleArray(userDetails?.activityDetails);
      }
    } else {
      if (Object.keys(redeemedUserList).length > 0) {
        handleArray(redeemedUserList?.memberAwards);
      }
    }
  }, [userDetails, tabActive, redeemedUserList]);

  return (
    <div className={styles["mileage-statement-mobile-section"]}>
      {tabActive !== "Miles Redeemed" ? (
        <>
          {userDetails?.activityDetails &&
          userDetails?.activityDetails?.length > 0 ? (
            <div className={styles["mobile-data-wrapper"]}>
              <div className={styles["mileage-transactions"]}>
                <div className={styles["number-of-transactions"]}>
                  {userDetails?.activityDetails?.length}{" "}
                  {t("label_transactions")}
                </div>
                <Button className={styles["download-button"]} onClick={onclick}>
                  {t("label_download_eReceipt_button")}
                  <TGIcon icon={"download-icon"} fillColor="none"></TGIcon>
                </Button>
              </div>
              <div className={styles["mobile-data-content-wrapper"]}>
                {userDetails?.activityDetails?.map((x: any, index: number) => {
                  return (
                    <div className={styles["mobile-expandables"]}>
                      <div className={styles["mobile-data-header"]}>
                        <div className={styles["mobile-header-date"]}>
                          {normalDateFormat(moment(x.activityDate))}
                        </div>
                        <div className={styles["mobile-header-miles"]}>
                          {x?.milesDetails?.filter(
                            (y: any) => y.labelName === "RedeemedMiles"
                          )[0]?.labelValue && <span>-</span>}
                          {
                            x?.milesDetails?.filter(
                              (y: any) =>
                                y?.labelName === "RedeemedMiles" ||
                                y?.labelName === "Miles Earned"
                            )[0]?.labelValue
                          }
                        </div>
                        <div
                          onClick={() =>
                            clickArrow(index, updateRedeemedMilesData)
                          }
                        >
                          {updateRedeemedMilesData[index]?.isOpen ? (
                            <TGIcon
                              icon={"up-arrow-icon"}
                              fillColor="#2D1656"
                              size="34"
                              strokeColor="#2D1656"
                            ></TGIcon>
                          ) : (
                            <TGIcon
                              icon={"down-arrow-icon"}
                              fillColor="#2D1656"
                              size="34"
                              strokeColor="#2D1656"
                            ></TGIcon>
                          )}
                        </div>
                      </div>
                      <div className={styles["mobile-description-wrapper"]}>
                        {x?.description[0]?.description}
                      </div>
                      {updateRedeemedMilesData[index]?.isOpen && (
                        <div className={styles["mobile-content-divider"]}></div>
                      )}
                      {updateRedeemedMilesData[index]?.isOpen && (
                        <div className={styles["mobile-expansion-wrapper"]}>
                          {/* need to check the logic */}
                          {updateRedeemedMilesData[index]?.milesDetails?.filter(
                            (y: any) => y?.labelName === "Miles Earned"
                          )[0] ? (
                            <>
                              <div
                                className={styles["mobile-view-data-container"]}
                              >
                                <div className={styles["mobile-header"]}>
                                  {t("label_miles_earned")}
                                </div>
                                <div className={styles["mobile-data"]}>
                                  {
                                    updateRedeemedMilesData[
                                      index
                                    ]?.milesDetails?.filter(
                                      (y: any) =>
                                        y?.labelName === "Miles Earned"
                                    )[0]?.labelValue
                                  }
                                </div>
                              </div>
                              <div
                                className={styles["mobile-view-data-container"]}
                              >
                                <div className={styles["mobile-header"]}>
                                  {t("label_qualifying_miles")}
                                </div>
                                <div className={styles["mobile-data"]}>
                                  {
                                    updateRedeemedMilesData[
                                      index
                                    ]?.milesDetails?.filter(
                                      (y: any) =>
                                        y?.labelName === "Qualifying miles"
                                    )[0]?.labelValue
                                  }
                                </div>
                              </div>
                              <div
                                className={styles["mobile-view-data-container"]}
                              >
                                <div className={styles["mobile-header"]}>
                                  {t("label_code")}
                                </div>
                                <div className={styles["mobile-data"]}>
                                  {
                                    updateRedeemedMilesData[
                                      index
                                    ]?.milesDetails?.filter(
                                      (y: any) => y?.labelName === "Code"
                                    )[0]?.labelValue
                                  }
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {updateRedeemedMilesData[
                                index
                              ]?.milesDetails?.filter(
                                (y: any) => y?.labelName === "Miles Expired"
                              )[0] ? (
                                <>
                                  <div
                                    className={
                                      styles["mobile-view-data-container"]
                                    }
                                  >
                                    <div className={styles["mobile-header"]}>
                                      {t("label_miles_ecpired")}
                                    </div>
                                    <div className={styles["mobile-data"]}>
                                      {
                                        updateRedeemedMilesData[
                                          index
                                        ]?.milesDetails?.filter(
                                          (y: any) =>
                                            y?.labelName === "Miles Expired"
                                        )[0]?.labelValue
                                      }
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      styles["mobile-view-data-container"]
                                    }
                                  >
                                    <div className={styles["mobile-header"]}>
                                      {t("label_code")}
                                    </div>
                                    <div className={styles["mobile-data"]}>
                                      {
                                        updateRedeemedMilesData[
                                          index
                                        ]?.milesDetails?.filter(
                                          (y: any) => y?.labelName === "Code"
                                        )[0]?.labelValue
                                      }
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className={
                                      styles["mobile-view-data-container"]
                                    }
                                  >
                                    <div className={styles["mobile-header"]}>
                                      {t("label_miles_redeemed")}
                                    </div>
                                    <div className={styles["mobile-data"]}>
                                      {updateRedeemedMilesData[
                                        index
                                      ]?.milesDetails?.filter(
                                        (y: any) =>
                                          y.labelName === "RedeemedMiles"
                                      )[0]?.labelValue && <span>-</span>}
                                      {
                                        updateRedeemedMilesData[
                                          index
                                        ]?.milesDetails?.filter(
                                          (y: any) =>
                                            y?.labelName === "RedeemedMiles"
                                        )[0]?.labelValue
                                      }
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      styles["mobile-view-data-container"]
                                    }
                                  >
                                    <div className={styles["mobile-header"]}>
                                      {t("label_code")}
                                    </div>
                                    <div className={styles["mobile-data"]}>
                                      {
                                        updateRedeemedMilesData[
                                          index
                                        ]?.milesDetails?.filter(
                                          (y: any) => y?.labelName === "Code"
                                        )[0]?.labelValue
                                      }
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className={styles["empty-element-wrapper"]}>
              <EmptyMileageData />
            </div>
          )}
        </>
      ) : redeemedUserList?.memberAwards?.length > 0 ? (
        <div className={styles["mobile-data-wrapper"]}>
          <div className={styles["mileage-transactions"]}>
            <div className={styles["number-of-transactions"]}>
              {redeemedUserList?.memberAwards?.length} {t("label_transactions")}
            </div>
          </div>
          <div className={styles["mobile-data-content-wrapper"]}>
            {redeemedUserList?.memberAwards?.map((x: any, index: number) => {
              return (
                <>
                  <div className={styles["mobile-expandables"]}>
                    <div className={styles["mobile-data-header"]}>
                      <div className={styles["mobile-header-date"]}>
                        {normalDateFormat(moment(x.requestDate))}
                      </div>
                      <div className={styles["mobile-header-miles"]}>
                        {x?.awardCode}
                      </div>
                      <div
                        onClick={() =>
                          clickArrow(
                            index,
                            updateRedeemedMilesData,
                            x?.requestNumber
                          )
                        }
                      >
                        {updateRedeemedMilesData?.length > 0 &&
                        updateRedeemedMilesData[index]?.isOpen ? (
                          <TGIcon
                            icon={"up-arrow-icon"}
                            fillColor="#2D1656"
                            size="34"
                            strokeColor="#2D1656"
                          ></TGIcon>
                        ) : (
                          <TGIcon
                            icon={"down-arrow-icon"}
                            fillColor="#2D1656"
                            size="34"
                            strokeColor="#2D1656"
                          ></TGIcon>
                        )}
                      </div>
                    </div>
                    <div className={styles["mobile-description-wrapper"]}>
                      {t("label_request_number")} - {x?.requestNumber}
                    </div>

                    {updateRedeemedMilesData[index]?.isOpen && (
                      <div className={styles["mobile-content-divider"]}></div>
                    )}
                    {updateRedeemedMilesData[index]?.isOpen && awardInfos && (
                      <div className={styles["mobile-expansion-wrapper"]}>
                        {/* need to check the logic */}

                        <>
                          <div className={styles["mobile-view-data-container"]}>
                            <div className={styles["mobile-header"]}>
                              {t("label_miles_redeemed")}
                            </div>
                            <div className={styles["mobile-data"]}>
                              {awardInfos[0]?.redeemedPoints}
                            </div>
                          </div>
                          <div className={styles["mobile-view-data-container"]}>
                            <div className={styles["mobile-header"]}>Code</div>
                            <div className={styles["mobile-data"]}>
                              {awardInfos[0]?.awardCode}
                            </div>
                          </div>
                          <div className={styles["mobile-view-data-container"]}>
                            <div className={styles["mobile-header"]}>
                              {t("label_request_number")}
                            </div>
                            <div className={styles["mobile-data"]}>
                              {milesAwardDetail.requestNumber}
                            </div>
                          </div>
                          {awardInfos[0]?.flightRedemptions?.length > 0 && (
                            <div
                              className={styles["mobile-view-data-container"]}
                            >
                              <div className={styles["mobile-header"]}>
                                {t("label_requested_for")}
                              </div>
                              <div className={styles["mobile-data"]}>
                                {awardInfos[0]?.awardUsers[0]?.firstName}{" "}
                                {awardInfos[0]?.awardUsers[0]?.lastName}
                              </div>
                            </div>
                          )}
                          {awardInfos[0]?.flightRedemptions[0]?.legInfo?.legs?.map(
                            (item: any, i: number) => (
                              <div className={styles["mobile-flight-wrapper"]}>
                                <div
                                  className={
                                    styles["mobile-flight-details-row"]
                                  }
                                >
                                  <div className={styles["flight-header"]}>
                                    {t("label_depart_date")}
                                  </div>
                                  <div className={styles["flight-data"]}>
                                    {normalDateFormat(moment(item?.flightDate))}
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles["mobile-flight-details-row"]
                                  }
                                >
                                  <div className={styles["flight-header"]}>
                                    {t("label_flight_header")}
                                  </div>
                                  <div className={styles["flight-data"]}>
                                    {item?.airlineCode}
                                    {item?.flightNumber}
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles["mobile-flight-details-row"]
                                  }
                                >
                                  <div className={styles["flight-header"]}>
                                    {t("label_from_to")}
                                  </div>
                                  <div className={styles["flight-data"]}>
                                    {item?.origin} - {item?.destination}
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles["mobile-flight-details-row"]
                                  }
                                >
                                  <div className={styles["flight-header"]}>
                                    {t("label_class")}
                                  </div>
                                  <div className={styles["flight-data"]}>
                                    {item?.className}
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles["mobile-flight-details-row"]
                                  }
                                >
                                  <div className={styles["flight-header"]}>
                                    {t("label_ticket_number")}
                                  </div>
                                  <div className={styles["flight-data"]}>
                                    {
                                      awardInfos[0]?.flightRedemptions[0]
                                        ?.ticketNumber
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles["empty-element-wrapper"]}>
          <EmptyMileageData />
        </div>
      )}
    </div>
  );
};

export default MobileView;
