// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fzo4RHN5vGQEqymsxKJ5 {
  width: 100%;
  height: max-content;
  overflow: hidden;
  scrollbar-width: none;
}
@media screen and (max-width: 767px) {
  .Fzo4RHN5vGQEqymsxKJ5 .slick-slider > .slick-list {
    height: 325px;
  }
}
.Fzo4RHN5vGQEqymsxKJ5 .slick-slider > .slick-list > .slick-track {
  display: flex;
}
.Fzo4RHN5vGQEqymsxKJ5 .slick-slider > .slick-list > .slick-track > .slick-slide {
  width: 33.33% !important;
}
@media screen and (max-width: 767px) {
  .Fzo4RHN5vGQEqymsxKJ5 .slick-slider > .slick-list > .slick-track > .slick-slide {
    padding: 0px;
    height: 100%;
  }
}
.Fzo4RHN5vGQEqymsxKJ5 .slick-slider > .slick-list > .slick-track > .slick-slide > div {
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/pages/mileage-statement/mobile-view/manage-miles-slider/manage-miles-slider.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;AAIgB;EADJ;IAEQ,aAAA;EADlB;AACF;AAGgB;EACI,aAAA;AADpB;AAGoB;EACI,wBAAA;AADxB;AAGwB;EAHJ;IAIQ,YAAA;IACA,YAAA;EAA1B;AACF;AAEwB;EACI,kBAAA;AAA5B","sourcesContent":[".carousel-container {\r\n    width: 100%;\r\n    height: max-content;\r\n    overflow: hidden;\r\n    scrollbar-width: none;\r\n\r\n    & :global {\r\n        .slick-slider {\r\n            >.slick-list {\r\n                @media screen and (max-width:767px) {\r\n                    height: 325px;\r\n                }\r\n\r\n                >.slick-track {\r\n                    display: flex;                \r\n\r\n                    >.slick-slide {\r\n                        width: 33.33% !important;\r\n\r\n                        @media screen and (max-width:767px) {\r\n                            padding: 0px;\r\n                            height: 100%;\r\n                        }\r\n\r\n                        >div{\r\n                            margin-right: 16px;\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel-container": `Fzo4RHN5vGQEqymsxKJ5`
};
export default ___CSS_LOADER_EXPORT___;
