import React, { useEffect, useRef, useState } from "react";
import styles from "./month-year-calendar.module.scss";
import { Button, Offcanvas } from "react-bootstrap";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import TGButton from "../../../../../shared/tg-button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { resetMileageInfoData } from "../../../slice/mileageStatementSlice";

interface DateValues {
  sendChildToParent?: any;
  setCalendarOpen: any;
  buttonHandle: any;
  id: string;
  selectedFilterType: any;
}

const MonthYearCalendar: React.FC<DateValues> = ({
  sendChildToParent,
  setCalendarOpen,
  buttonHandle,
  id,
  selectedFilterType,
}) => {
  const { t } = useTranslation();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const isDesktopView = useScreenSize()?.deviceSize?.width > 767;

  document.documentElement.style.setProperty("--overlay-width", "100%");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromIndex, setFromIndex] = useState(12);
  const [toIndex, setToIndex] = useState(12);
  const [closePopup, setClosePopup] = useState(true);
  const [range, setRange] = useState<any>({ from: null, to: null });
  const [buttonDisable, setButtonDisabled] = useState(true);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const prevYear = currentYear - 1;
  const contentRef: any = useRef(null);

  const { data } = useSelector((state: RootState) => state?.osciReducer?.mileageStatement);

  const dispatch = useDispatch();

  const handleClickOutside = (e: any) => {
    if (
      isDesktopView &&
      contentRef.current &&
      !contentRef.current.contains(e.target)
    ) {
      setCalendarOpen(false);
    }
  };

  const handleMonthClick = (month: any, year: any) => {
    let str = months[month] + ", " + year;
    const selectedMonth = { month, year, selectionTrue: true };
    if (
      !range.from &&
      ((currentMonth <= month && currentYear > year) ||
        (currentMonth >= month && currentYear === year))
    ) {
      setRange({
        from: selectedMonth,
        to: null,
        fromIndex: month,
        toIndex: null,
      });
      setFromDate(str);
      sendChildToParent(str, toDate);
      setFromIndex(month);
      setButtonDisabled(true);
    } else if (
      !range.to &&
      ((currentMonth <= month && currentYear > year) ||
        (currentMonth >= month && currentYear === year))
    ) {
      if (
        selectedMonth.year > range.from?.year ||
        (selectedMonth.year === range.from?.year &&
          selectedMonth.month > range.from?.month)
      ) {
        setRange({ ...range, to: selectedMonth, toIndex: month });
        setToDate(str);
        sendChildToParent(fromDate, str);
        setToIndex(month);
        setButtonDisabled(false);
      } else if (
        (currentMonth <= month && currentYear > year) ||
        (currentMonth >= month && currentYear === year)
      ) {
        //to value should be handled
        setRange({
          from: selectedMonth,
          to: null,
          fromIndex: month,
          toIndex: null,
        });
        setFromDate(str);
        sendChildToParent(str, "");
        setFromIndex(month);
        setButtonDisabled(true);
      }
    } else if (
      (currentMonth <= month && currentYear > year) ||
      (currentMonth >= month && currentYear === year)
    ) {
      setRange({
        from: selectedMonth,
        to: null,
        fromIndex: month,
        toIndex: null,
      });
      setFromDate(str);
      sendChildToParent(str, "");
      setButtonDisabled(true);
      setFromIndex(month);
    }
  };

  const handleButtonClick = () => {
    if (data) {
      dispatch(resetMileageInfoData());
    }
    buttonHandle();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={contentRef}>
      {isDesktopView ? (
        <div className={styles["calendar-wrapper"]} id={id}>
          <div className={styles["year-month-wrapper"]}>
            <div className={styles["month-calendar"]}>
              <div className={styles["year-wrapper"]}>
                <h4 className={styles["year-text"]}>{prevYear}</h4>
              </div>
              <div className={styles["month-grid"]}>
                {months.map((month, index) => (
                  <div
                    key={index}
                    className={styles["month-cell"]}
                    style={{
                      background:
                        (range.from?.selectionTrue &&
                          index === range?.fromIndex &&
                          prevYear === range.from?.year) ||
                        (range.to?.selectionTrue &&
                          index === range?.toIndex &&
                          prevYear === range.to?.year)
                          ? "#8062B4"
                          : "#FFF",
                    }}
                  >
                    <div
                      className={
                        index >= currentMonth
                          ? styles["month-text"]
                          : styles["disabled-month-text"]
                      }
                      onClick={() => handleMonthClick(index, prevYear)}
                      style={{
                        color:
                          ((range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            prevYear === range.from?.year) ||
                            (range.to?.selectionTrue &&
                              index === range?.toIndex &&
                              prevYear === range.to?.year)) &&
                          "#fff",
                      }}
                    >
                      {month}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles["separator"]}></div>

            <div className={styles["month-calendar"]}>
              <div className={styles["year-wrapper"]}>
                <h4 className={styles["year-text"]}>{currentYear}</h4>
              </div>
              <div className={styles["month-grid"]}>
                {months.map((month, index) => (
                  <div
                    key={index}
                    className={styles["month-cell"]}
                    style={{
                      background:
                        (range.from?.selectionTrue &&
                          index === range?.fromIndex &&
                          currentYear === range.from?.year) ||
                        (range.to?.selectionTrue &&
                          index === range?.toIndex &&
                          currentYear === range.to?.year)
                          ? "#8062B4"
                          : "#FFF",
                    }}
                  >
                    <div
                      className={
                        index <= currentMonth
                          ? styles["month-text"]
                          : styles["disabled-month-text"]
                      }
                      onClick={() => handleMonthClick(index, currentYear)}
                      style={{
                        color:
                          ((range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            currentYear === range.from?.year) ||
                            (range.to?.selectionTrue &&
                              index === range?.toIndex &&
                              currentYear === range.to?.year)) &&
                          "#FFF",
                      }}
                    >
                      {month}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles["calendar-button"]}>
            <TGButton
              customClass={styles["button-style"]}
              onClick={() => handleButtonClick()}
              disabled={buttonDisable}
              label={t("button_confirm")}
              variant="primary"
            ></TGButton>
          </div>
        </div>
      ) : (
        <Offcanvas
          show={closePopup}
          placement="bottom"
          onHide={() => setClosePopup(false)}
          className={styles["flight-status-offcanvas"]}
          backdropClassName={styles["flight-status-offcanvas-back"]}
        >
          <Offcanvas.Body className={styles["flight-status-offcanvas-body"]}>
            <div>
              <div className={styles["flight-status-label"]}>{prevYear}</div>
              <div className={styles["mobile-month-container"]}>
                <div className={styles["mobile-month-grid"]}>
                  {months.map((month, index) => (
                    <div
                      className={styles["mobile-month-wrapper"]}
                      style={{
                        background:
                          (range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            prevYear === range.from?.year) ||
                          (range.to?.selectionTrue &&
                            index === range?.toIndex &&
                            prevYear === range.to?.year)
                            ? "#8062B4"
                            : "#FFF",
                        borderRadius:
                          (range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            prevYear === range.from?.year) ||
                          (range.to?.selectionTrue &&
                            index === range?.toIndex &&
                            prevYear === range.to?.year)
                            ? "26px"
                            : "none",
                      }}
                    >
                      <div className={styles["mobile-month-padded"]}>
                        <div
                          className={
                            index < currentMonth
                              ? styles["mobile-month-text"]
                              : styles["mobile-month-active"]
                          }
                          onClick={() => handleMonthClick(index, prevYear)}
                          style={{
                            color:
                              ((range.from?.selectionTrue &&
                                index === range?.fromIndex &&
                                prevYear === range.from?.year) ||
                                (range.to?.selectionTrue &&
                                  index === range?.toIndex &&
                                  prevYear === range.to?.year)) &&
                              "#FFF",
                          }}
                        >
                          {month}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className={styles["flight-status-label"]}>{currentYear}</div>
              <div className={styles["mobile-month-container"]}>
                <div className={styles["mobile-month-grid"]}>
                  {months.map((month, index) => (
                    <div
                      className={styles["mobile-month-wrapper"]}
                      style={{
                        background:
                          (range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            currentYear === range.from?.year) ||
                          (range.to?.selectionTrue &&
                            index === range?.toIndex &&
                            currentYear === range.to?.year)
                            ? "#8062B4"
                            : "#FFF",
                        borderRadius:
                          (range.from?.selectionTrue &&
                            index === range?.fromIndex &&
                            currentYear === range.from?.year) ||
                          (range.to?.selectionTrue &&
                            index === range?.toIndex &&
                            currentYear === range.to?.year)
                            ? "26px"
                            : "none",
                      }}
                    >
                      <div className={styles["mobile-month-padded"]}>
                        <div
                          className={
                            index > currentMonth
                              ? styles["mobile-month-text"]
                              : styles["mobile-month-active"]
                          }
                          onClick={() => handleMonthClick(index, currentYear)}
                          style={{
                            color:
                              (range.from?.selectionTrue &&
                                index === range?.fromIndex &&
                                currentYear === range.from?.year) ||
                              (range.to?.selectionTrue &&
                                index === range?.toIndex &&
                                currentYear === range.to?.year &&
                                "#FFF"),
                          }}
                        >
                          {month}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Offcanvas.Body>
          <div className={styles["tg-datepicker-confirm-button"]}>
            <TGButton
              label={t("button_confirm")}
              variant="primary"
              onClick={() => handleButtonClick()}
              disabled={buttonDisable}
            ></TGButton>
          </div>
        </Offcanvas>
      )}
    </div>
  );
};

export default MonthYearCalendar;
