import React, { useRef, useState } from "react";
import styles from "./manage-miles-slider.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import { ManageMilesCard } from "../../../mileage-statement";

const ManageMilesSlider: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const { manageMiles } = useSelector(
    (state: RootState) => state?.osciReducer?.mileageStatement
  );
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    initialSlide: 0,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <>
      <div className={styles["carousel-container"]}>
        <Slider ref={sliderRef} {...settings}>
          {manageMiles.length > 0 &&
            manageMiles?.map((x: any, index: number) => (
              <>{ManageMilesCard(x, index)}</>
            ))}
        </Slider>
      </div>
    </>
  );
};

export default ManageMilesSlider;
